import React, {Component} from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';

class TimeCard extends Component {
  render(){
    const {classes, title, time, image} = this.props

    let time_data = '00:00'
    if(time){
      time_data = time.substring(0, 5);
    }

    return(
      <div>
        <CardWrapper
        >
          <div className={classes.root}>
            <Grid container spacing={2} wrap='nowrap'>
              <Grid item>
                <img src={image} alt='' className={classes.image}/>
              </Grid>
              <Grid item xs>
                <Grid container>
                  <Grid item xs={12}>
                    <ContentText text={title} medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems='baseline' wrap='nowrap' >
                      <Grid item >
                        <Typography className={classes.hour}>
                          {time_data}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={classes.label}>
                          hrs
                        </Typography>
                      </Grid>
                    </Grid>
                    
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </div>
        </CardWrapper>
      </div>
    )
  }
}

const styles = theme => ({
  root:{
    padding:16,
    paddingTop:16,
    paddingBottom:16,
    boxSizing:'border-box',
    height:100
  },
  image:{
    width:64,
  },
  hour:{
    fontSize:24,
    fontWeight:500,
    color:theme.palette.primary.main,
    
    [theme.breakpoints.only('md')]: {
      fontSize:18,
    },
  },
  label:{
    fontWeight:500,
    color:grey[700],
  }
})

export default withStyles(styles)(TimeCard)