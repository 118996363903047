export const formData = {
  record_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'record_type_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  notes:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'notes',
      type:'text',
      fullWidth: true,
      multiline:true,
      rows:3
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
}