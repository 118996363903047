import React, {Component} from 'react'
import { withStyles, Grid, Icon, Avatar } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';

import moment from 'moment';
import { blue, grey, lightBlue } from '@material-ui/core/colors';
import Caption from '../../../../components/Texts/Caption';
import ContentText from '../../../../components/Texts/ContentText';



class AttendanceCard extends Component {
  render(){
    const {classes, onViewDetails, data} = this.props

    const data2view = this.onCleanData(data)

    return(
      <div>
        <CardWrapper
          title='Asistencias'
          link='Ver más'
          onClickLink={onViewDetails}
        >
          <div className={classes.root}>
            <div className={classes.subroot}>
              <Grid container>
                {data2view.map((item,key)=>{
                  return(
                    <Grid item xs={12} key={key.toString()}>
                      <div className={classes.container}>
                        <Grid container wrap='nowrap' spacing={1} alignItems='flex-start'>
                          <Grid item>
                            <Avatar style={{background:item.color, marginTop:4}}>
                              <Icon style={{color:'white'}}>{item.icon}</Icon>
                            </Avatar>
                          </Grid>
                          <Grid item xs>
                            <Grid container>
                              <Grid item xs={12}>
                                <Caption text={item.date} medium variant='light'/>
                              </Grid>
                              <Grid item xs={12}>
                                <ContentText text={item.hour} medium />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Caption text={item.record_type} medium />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  )
                })}
                {data2view.length === 0 ? (
                  <Grid item xs={12}>
                    <div style={{paddingTop:32, paddingBottom:32}}>
                      <ContentText text='No existen registros' medium align='center'/>
                    </div>
                  </Grid>
                  ) : 
                null}
              </Grid>
            </div>
            
          </div>
        </CardWrapper>
      </div>
    )
  }

  onCleanData = (data) => {

    const {months} = this.props

    

    return data.map(item => {

      const moment_time = moment(item.timestamp)
      let date = '00-00-0000'
      let hour = '00:00 hrs'
      if(moment_time.isValid()){
        date = `${moment_time.date().toString()} ${months[moment_time.month()].toUpperCase()}, ${moment_time.year()} ` 
        hour = `${moment_time.format('HH:mm')} hrs`
      }

      let icon = ''
      let color = grey[700]
      switch (item.record_type_id) {
        case 1:
          color = blue[900]
          icon = 'work'
          break;
        case 2:
          color = lightBlue[600]
          icon = 'work'
          break;
        case 3:
          color = blue[900]
          icon = 'single_bed'
          break;
        case 4:
          color = lightBlue[600]
          icon = 'single_bed'
          break;
        case 5:
          color = blue[900]
          icon = 'emoji_food_beverage'
          break;
        case 6:
          color = lightBlue[600]
          icon = 'emoji_food_beverage'
          break;
        default:
          break;
      }

      return {
        id:item.id,
        date:date,
        hour:hour,
        color:color,
        icon:icon,
        record_type:item.record_type
      }
    })
  }
}

const styles = theme => ({
  root:{
    padding:16,
    
  },
  subroot:{
    maxHeight:500,
    overflowX:'hidden',
    overflowY:'auto'
  },
  container:{
    padding:8,
    margin:4,
    borderRadius:8,
    background:grey[100]
  }
})

export default withStyles(styles)(AttendanceCard)