import { green, grey, amber } from "@material-ui/core/colors";

export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Usuarios',
      navigation:[
        {name:'Usuarios',path:'/users'},
      ],
    },
    table:{
      nodata:'Sin registro',
      header:[
        {id:1, label:'Nombre', flex:1},
        {id:2, label:'Disponibilidad'},
        {id:3, label:'Úlitmo registro'},
        {id:4, label:'Tipo'},
        {id:5, label:'Estatus'},
        {id:6, label:'E-mail'},
        {id:7, label:'Teléfono'},
      ],
      filter_list:[
        {id:1, label:'Todo'},
      ],
      catalogs: {
        gender:[
          {id:1, label:'Todo'},
          {id:2, label:'Hombre'},
          {id:3, label:'Mujer'},
        ]
      },
      table_footer:{
        row_numbers: 'No.filas',
        joint:'de'
      },
      table_row:[
        {
          type:'avatar',
          image_key:'image',
          flex:1,
          keys:['first_name','last_name'],
        },
        {
          type:'status',
          colors:[green[700],amber[700],grey[400]],
          id_key:'availability_id',
          keys:['availability']
        },
        {
          type:'data',
          keys:['last_record_type'],
        },
        {
          type:'data',
          keys:['user_type'],
        },
        {
          type:'data',
          keys:['employee_status'],
        },
        {
          type:'data',
          keys:['work_email'],
          icon:{
            name:'mail'
          }
        },
        {
          type:'data',
          keys:['work_phone'],
          icon:{
            name:'phone'
          }
        },
      ]
    },
    
  }
}