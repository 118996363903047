import {mainServer} from '../../../variables/config'
import axios from 'axios'

export const request_user = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/user/${id}`)
  return response.data.data.user
}
export const request_update_user = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch(`/user/${id}`,data)
  let response
  response = await mainServer.instance.get(`/user/${id}`)
  return response.data.data.user
}

export const request_delete_user = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete(`/user/${id}`)
}

export const request_delete_image = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = `/user/${id}/image`
  await mainServer.instance.delete(url)
  let response
  response = await mainServer.instance.get(`/user/${id}`)
  return response.data.data.user
}

export const request_upload_image = async(id,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = `/user/${id}/image`
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.upload_url, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/user/${id}`)
  return response.data.data.user
}