import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import MasterTableA from '../../../components/Tables/MasterTableA'
import { request_areas, request_users, request_add_area, request_edit_area, request_delete_area } from './requests';
import { Fab, Icon } from '@material-ui/core';
import AddAreaModal from './modals/AddAreaModal/AddAreaModal';
import EditAreaModal from './modals/EditAreaModal/EditAreaModal';

class Areas extends Component {

  state = {
    isloading:false,
    areas:[],
    selected_area:null,
    table_config:{
      rows:10,
      page:0,
      count:0,
      filter_selected:null,
      search_text:null,
    },
    modals:{
      add_area:{open:false,issending:false,error:null},
      edit_area:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language} = this.props
    const {isloading, table_config, areas, users, modals, selected_area} = this.state
    const content = contentData[language]

    let temp = Object.keys(areas).map((item)=>{
      let image = null
      if(users){
        let responsible = users.find(el => el.id_user === areas[item].responsible_id)
        image = responsible ? responsible.image : null
      }
      return {
        ...JSON.parse(JSON.stringify(areas[item])),
        image:image
      }
    })

    let modalContent = null
    let custom_catalogs = {
      users:[...this.getUsersCatalog(users)]
    }

    if(modals.add_area.open){

      

      modalContent = (
        <AddAreaModal
          language={language}
          catalogs={{...custom_catalogs}}
          content={content.add_area_modal}
          data={modals.add_area}
          onClose={() => this.onCloseModal('add_area')}
          onSubmit={this.onAddArea}
        />
      )
    }

    if(modals.edit_area.open){

      modalContent = (
        <EditAreaModal
          origin_data={selected_area}
          language={language}
          catalogs={{...custom_catalogs}}
          content={content.edit_area_modal}
          data={modals.edit_area}
          onClose={() => this.onCloseModal('edit_area')}
          onSubmit={this.onEditArea}
          onDelete={this.onDeleteArea}
        />
      )
    }

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
      >
        <div className={classes.root}>
          {modalContent}
          <Fab className={classes.button} color='primary' onClick={() => this.onOpenModal('add_area')}>
            <Icon >add</Icon>
          </Fab>
          <MasterTableA 
            data={temp}
            table={content.table} 
            config={table_config}
            onSelectedRow={this.onSelectedRow}
            onSearch={this.onSearch}
            onChangePageNumber={this.onChangePageNumber}
            onChangePage={this.onChangePage}/>
        </div>
      </BasicView>
    )
  }

  onAddUser = () => {
    this.props.history.push(`/areas/add`)
  }

  onAddArea = async(data) => {
    const {table_config} = this.state
    const type = 'add_area'
    this.onEditModal(type,{issending:true})
    
    try {
      await request_add_area(data)
      let response = await request_areas({
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({areas:[...response.areas]})
      this.updateTableConfig('count',response.count)
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onEditArea = async(data) => {
    const {table_config, selected_area} = this.state
    const type = 'edit_area'
    this.onEditModal(type,{issending:true})
    
    try {
      await request_edit_area(selected_area.id_area, data)
      let response = await request_areas({
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({areas:[...response.areas]})
      this.updateTableConfig('count',response.count)
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onDeleteArea = async() => {
    const {table_config, selected_area} = this.state
    const type = 'edit_area'
    this.onEditModal(type,{issending:true})
    
    try {
      await request_delete_area(selected_area.id_area)
      let response = await request_areas({
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({areas:[...response.areas]})
      this.updateTableConfig('count',response.count)
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        let e = {...error.response.data.error}
        this.onEditModal(type,{error:`#${e.code}, ${e.message}`})
      }else{
        this.onEditModal(type,{error:`No Internet`})
      }
    }
  }

  onSelectedRow = (item) => {
    console.log(item)
    this.setState({
      selected_area:{...item}
    })
    this.onOpenModal('edit_area')
    //this.props.history.push(`/areas/${item.id_user}`)
  }

  onSearch = (value) => {
    let temp = {...this.state.table_config}
    temp.search_text = value
    this.setState({table_config:{...temp}})
  }

  onChangePageNumber = (number) => {
    let temp = {...this.state.table_config}
    temp.rows = number
    temp.page = 0
    this.setState({table_config:{...temp}})
  }

  onChangePage = (number) => {
    let temp = {...this.state.table_config}
    temp.page = number
    this.setState({table_config:{...temp}})
  }

  componentDidMount(){
    const data = localStorage.getItem('data')
    if(data){
      const user_crendentials = JSON.parse(data)
      if(user_crendentials.user_type_id > 1){
        this.props.history.push('/')
      }else{
        this.initModule()
      }
    }else{
      this.props.history.push('/signin')
    }
  }

  initModule = async() => {
    const {table_config} = this.state
    this.setState({isloading:true})
    try {
      let response = await request_areas({
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({areas:[...response.areas]})
      this.updateTableConfig('count',response.count)
      response =  await request_users()
      this.setState({users:[...response]})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  updateTableConfig = (key, data) => {
    const {table_config} = this.state
    let temp = {...table_config}
    temp[key] = data
    this.setState({table_config:{...temp}})
  }

  getUsersCatalog = (users) => {
    let response = []
    if(users){
      response = users.map(item => {
        let fullname = ''
        fullname += item.first_name ? item.first_name : '' 
        fullname += item.last_name ? (' ' + item.last_name) : '' 
        return {
          value:item.id_user,
          label:fullname
        }
      })
    }
    

    return response
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

}

const styles = theme => ({
  root:{
    position:'relative'
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(Areas)