export const contentData = {
  spanish:{
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
        helper:'Nombre no válido'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
        helper:'Seleccione un responsable'
      },
      description:{
        label:'Descripción',
        placeholder:'Descripción (opcional)',
        helper:'Descripción no válida'
      },
    }
  }
}