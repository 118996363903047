import React, { Component } from 'react';
import { withStyles,  Grid } from '@material-ui/core';
import BasicModal from '../../../../../components/Modals/BasicModal';
import {contentData} from './content'
import {formData} from './data'
import ShadowInputSelect from '../../../../../components/Inputs/ShadowInputSelect/ShadowInputSelect';
import ShadowInputText from '../../../../../components/Inputs/ShadowInputText/ShadowInputText';

class EditAreaModal extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData))
  }
  
  render(){

    const {onClose, data, content, onDelete} = this.props
    const {formData} = this.state

    let formContent = null
    formContent = Object.keys(formData).map((item,key)=>{
      let inputContent = null
      switch (formData[item].config.type) {
        case 'select':
          inputContent = (
            <ShadowInputSelect 
              data={formData[item]}
              onChange={this.onInputChange}/>
          )
          break;
        
        default:
          inputContent = (
            <ShadowInputText data={formData[item]} onChange={this.onInputChange}/>
          )
          break;
      }
      return(
        <Grid item xs={12} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })

    return(
      <BasicModal
        open={true}
        onClose={onClose}
        content={content}
        onSubmit={this.onSubmit}
        onDelete={onDelete}
        isloading={data ? data.issending : null}
        error={data ? data.error : null}
      >
        <Grid container spacing={2}>
          {formContent}
        </Grid>
      </BasicModal>
        
    )
  }

  onSubmit = () => {
    const {onSubmit, origin_data} = this.props;
    const {formData} = this.state;
    let temp = {...formData}

    let isFormGood = true
    Object.keys(formData).forEach(item => {
      if(temp[item].isRequired){
        if(!temp[item].isValid){
          isFormGood = false
          temp[item].isVisited = true
        }
      }
    })

    if(isFormGood){
      let data2Send = {};
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(origin_data){
            if(formData[item].value !== origin_data[item]){
              data2Send = {...data2Send, [item]:formData[item].value}
            }
          }else{
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
      onSubmit(data2Send)
    }else{
      this.setState({formData:{...temp}})
    }
  }

  componentDidMount(){
    const {origin_data, language, catalogs} = this.props
    const {formData} = this.state
    const content = contentData[language]
    let temp = {...formData}
    temp['responsible_id'].options = [...catalogs.users]

    Object.keys(formData).forEach((item) => {
      if(origin_data){
        temp[item].value = origin_data[item]
        temp[item].isVisited = origin_data[item] ? true : false
        temp[item].isValid = origin_data[item] ? true : false
      } // update data
      temp[item].config = {...temp[item].config,...content.form[item]};
    })
    this.setState({formData:{...temp}})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }
}

const styles = theme => ({
  root:{
    padding:16,
    boxSizing:'border-box',
    width:400,
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    },
  }
})

export default withStyles(styles)(EditAreaModal)