import React, {Component} from 'react'
import { withStyles, Drawer, Grid, withWidth, Typography, Icon } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import cx from 'classnames'
import logo from '../../../assets/company/logo_big_white.png'
import SidebarAvatar from '../../../components/Avatars/SidebarAvatar';

import {layout} from '../../../variables/config';



class Sidebar extends Component {
  render(){
    const {classes, user, open, width, onClose, routes, history, onChange} = this.props

    let r_user = user ? {...user} : {}

    return(
      <div>
        <Drawer
          className={classes.drawer}
          classes={{
            paper:classes.paper
          }}
          variant={width!=='sm' && width!=='xs' && width!=='md' ? 'persistent' : 'temporary'}
          open={open}
          onClose={onClose}
        >
          <div className={classes.root}>
            <Scrollbars autoHeightMin="100%" autoHeightMax="100%" 
              renderTrackHorizontal={props => <div {...props} style={{display: 'none', overflow:'hidden'}} className="track-horizontal"/>}
              renderView={props => (
                <div {...props} style={{ ...props.style, overflowX: 'hidden', }} />
              )}
            >
              <div style={{paddingBottom:32}}>
                <Grid container justify='center' alignItems='center'>
                  <Grid item>
                    <img src={logo} alt='' className={classes.imageContainer} />
                  </Grid>
                  <Grid item>
                    <SidebarAvatar image={user ? user.image : null} name={r_user ? `${r_user.first_name} ${r_user.last_name ? r_user.last_name : ''}` : null}/>
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{marginTop:40}}>
                      <Grid container>
                        {routes.sections.map((item,key)=>{
                          if(item.sidebar &&  r_user.user_type_id <= item.level){
                            
                            const selected =  this.isCompatibleURL(history,item.path);

                            return(
                              <Grid item xs={12} key={key.toString()}>
                                <div 
                                  onClick={() => onChange(item.path)}
                                  className={cx({
                                    [classes.button]:true,
                                    [classes.buttonSelected]:selected
                                  })}
                                >
                                  <Grid container alignItems='center' spacing={1}>
                                    <Grid item>
                                      <Icon className={cx({
                                        [classes.icon]:true,
                                        [classes.iconSelected]:selected
                                      })}>{item.icon}</Icon>
                                    </Grid>
                                    <Grid item xs>
                                      <Typography className={cx({
                                        [classes.label]:true,
                                        [classes.labelSelected]:selected,
                                      })} variant='caption'>
                                        {item.label}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </div>
                              </Grid>
                            )
                          }
                          return null
                        })}
                      </Grid>
                    </div>
                    
                  </Grid>
                </Grid>
              </div>
            </Scrollbars>
          </div>
        </Drawer>
      </div>
    )
  }

  isCompatibleURL = (history, path) => {
    return history.location.pathname.split('/')[1] === path.split('/')[1];
  }
}

const styles = (theme) => ({
  root:{
    height:'100vh'
  },
  paper:{
    width:layout.width,
    background:theme.palette.primary.main,
    borderTopRightRadius:16,
    border:0
  },
  imageContainer:{
    width:130,
    margin:'auto'
  },
  icon:{
    marginTop:6,
    color:'white',
  },
  iconSelected:{
    color:theme.palette.primary.main
  },
  label:{
    color:'white',
    fontWeight:500
  },
  labelSelected:{
    color:theme.palette.primary.main
  },
  button:{
    borderRadius:0,
    boxSizing:'border-box',
    padding:16,
    '&:hover':{
      background:'rgba(255,255,255,0.3)',
      cursor:'pointer'
    },
  },
  buttonSelected:{
    marginLeft:8,
    borderRadius:0,
    borderTopLeftRadius:16,
    borderBottomLeftRadius:16,
    background:'#f1f5f8',
    '&:hover':{
      background:'#f1f5f8',
    },
  }
  
})

export default withStyles(styles)(withWidth()(Sidebar))