import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Grid,
  Typography,
  withWidth,
  InputAdornment,
  Icon,
  InputBase
} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, red} from '@material-ui/core/colors';
import ContentText from '../../Texts/ContentText.jsx';
import cx from 'classnames'

class ShadowInputText extends Component{

  onInnerChange = event => {
    const {onChange, data} = this.props;
    const response = event.target.value;
    let temp = {...data};
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  render(){

    const {
      classes,
      onKeyPress,
      onKeyUp,
      disabled,
    } = this.props;

    const {value, isVisited, isValid, isRequired} = this.props.data;
    const {type,label,placeholder,fullWidth,icon, multiline, rows, helper} = this.props.data.config;

    const error = isVisited && !isValid;

    let inputaddornment = null;
    if(icon){
      inputaddornment = 
      <InputAdornment position='start'>
        <Icon style={{color:blueGrey[500]}}>
          {icon.name}
        </Icon>
      </InputAdornment>
    }

    let inputlabel = null;
    if(label){
      let signal = isRequired ? '*' : ''
      inputlabel = (
        <Grid item>
          <ContentText text={label+signal} variant='light' medium/>
        </Grid>
      )
    }

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='column' spacing={0}>
            {inputlabel}
            <Grid item>
                <InputBase
                  className={cx({
                    [classes.inputBase]:!multiline,
                    [classes.inputMultilineBase]:multiline,
                  })}
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  error={error}
                  value={value ? value : ''}
                  onChange={this.onInnerChange}
                  multiline={multiline}
                  rows={rows}
                  type={type}
                  startadornment={inputaddornment}
                  onKeyUp={onKeyUp}
                  onKeyPress={onKeyPress}
                  inputProps={{
                    spellCheck:"false",
                    autoComplete:"false",
                    name:"hidden",
                  }}
                />
            </Grid>
            <Grid item>
              <Typography variant='caption' style={{color:red[700], fontWeight:500}}>
                {error ? helper : ''}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  }

};

export default withStyles(styles)(withWidth()(ShadowInputText));
