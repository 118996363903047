const styles = theme => ({
  root:{
    padding: 8,
    '&:hover':{
      cursor:'pointer'
    }
  },
  avatar:{
    margin: 3,
    backgroundColor: 'transparent',
    color: 'white',
    border:`2px solid ${'white'}`
  },
  button:{
    padding:"10px 0px",
    borderRadius:'5px',
    "&:hover": {
        backgroundColor:"#EEE"
    }
  },
  text:{
    color:'white'
  },
  user:{
    color:'rgba(255,255,255,1)',
  },
 
});

export default styles;
