import {mainServer} from '../../variables/config'

export const request_login = async(data) => {
    //console.log('Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password))
    mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
    let response;
    response = await mainServer.instance.post("/login",data);
    //console.log(response.data.data)
    localStorage.setItem('data',JSON.stringify(response.data.data));
}

