import React from 'react';
import {Paper, Grid, IconButton, Icon} from '@material-ui/core'
import Subtitle from '../../Texts/Subtitle';
import { palette } from '../../../variables/config';
import { grey } from '@material-ui/core/colors';
import Caption from '../../Texts/Caption';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  paper:{
    borderRadius:8,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  },
  link:{
    '&:hover':{
      cursor:'pointer',
      textDecoration:'underline',
      textDecorationColor:theme.palette.primary.main
    }
  }
}))

const CardWrapper = (props) => {

  const {title, color, onEdit, onClickLink, link} = props
  let toolbarContent = null

  const classes = useStyles()

  if(title){
    let buttonContent = null
    if(onEdit){
      buttonContent = (
        <Grid item>
          <IconButton size='small' onClick={onEdit}>
            <Icon fontSize='small' style={{color: color ? color : grey[500]}}>edit</Icon>
          </IconButton>
        </Grid>
      )
    }

    if(link){
      buttonContent = (
        <Grid item>
          <div className={classes.link} onClick={onClickLink}>
            <Caption text={link} color={color ? color : palette.primary.main} medium/>
          </div>
        </Grid>
      )
    }

    toolbarContent = (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs>
          <Subtitle text={title} medium color={color ? color : palette.primary.main}/>
        </Grid>
        {buttonContent}
      </Grid>
    </Grid>
    )
  }

  return(
    <Grid container>
      {toolbarContent}
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {props.children}
        </Paper>
      </Grid>
    </Grid>
    
  )
}

export default CardWrapper;