import { fade } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const styles = theme => ({
  inputBase: {
    
    '& input':{
      background: fade(theme.palette.primary.light,0.2),
      marginTop:6,
      color:grey[900],
      fontSize:14,
      fontWeight:'500',
      borderBottomWidth:0,
      borderRadius:8,
      padding:16,
    }
  },
  inputMultilineBase:{
    background: fade(theme.palette.primary.light,0.2),
      marginTop:6,
      color:grey[900],
      fontSize:14,
      fontWeight:'500',
      borderBottomWidth:0,
      borderRadius:8,
      padding:16,
  }
});

export default styles;
