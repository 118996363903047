export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Editar perfil',
      navigation:[
        {name:'Mi perfil',path:'/me'},
        {name:'Editar perfil',path:'/me/edit_profile'},
      ]
    },
    password_modal:{
      title:'Cambio de contraseña',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    }
    
  }
}