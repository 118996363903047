import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Popper,
  Fade,
  Paper,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import ContentText from '../Texts/ContentText';
import { fade } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
  popper:{
    marginTop:8,
    zIndex:3000,
  },
  iconUserMenu:{
    color: theme.palette.primary.main,
  },
  menuitem:{
    '&:hover':{
      background: fade(theme.palette.primary.main,0.1)
    }
  },
  paper:{
    borderRadius:8,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    
  },
  menu:{
    maxHeight:'200px',
    overflowY:'auto'
  }
});


class GenericPopper extends Component{
  render(){

    const {open, anchorEl, onClickAway, onSelectedItem, classes, menu} = this.props;


    let menuContent = null
    if(menu){
      menuContent = menu.map((item, key) => {
        return(
          <MenuItem className={classes.menuitem} key={key.toString()} onClick={() => onSelectedItem(item)}>
            <ContentText text={item.label}/>
          </MenuItem>
        )
      })
    }
      
    

    return(
        <Popper 
          open={open} 
          anchorEl={anchorEl} 
          placement={'bottom'} 
          transition 
          className={classes.popper} 
          style={{minWidth:'150px'}}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={onClickAway} >
                  <MenuList className={classes.menu}>
                    {menuContent}
                  </MenuList>

                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
    );
  }
};

export default withStyles(styles)(GenericPopper)
