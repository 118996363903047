import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import cx from 'classnames'
import { palette } from '../../variables/config';
import ContentText from '../Texts/ContentText';
import { blueGrey } from '@material-ui/core/colors';


class SmallNavigation extends Component {
  render(){

    const {classes, onChange, options, selected, secondary} = this.props

    return(
      <div className={classes.container}>
        <div className={cx({
          [classes.root]:true,
          [classes.secondary_root]:secondary
        })}>
          <Grid container alignItems='center' wrap='nowrap'>
            {options.map((item,key)=>{
              
              const isSelected = selected === item.id

              return(
                <Grid item key={key.toString()}>
                  <div 
                    onClick={() => onChange(item.id)}
                    className={cx({
                    [classes.button]:true,
                    [classes.firstButton]:key===0,
                    [classes.lastButton]:key===(options.length-1),
                    [classes.selectedButton]:isSelected
                  })}>
                    <ContentText 
                      text={item.label} 
                      medium={isSelected}
                      color={isSelected ? 'white' : palette.primary.main } 
                      align='center'/>
                  </div>
                </Grid> 
              )
            })}
          </Grid>
        </div>
      </div>
      
    )
  }
}

const styles = theme => ({
  container:{
    overflowY:'hidden',
    overflowX:'auto'
  },
  root:{
    background: fade('#FFF',1),
    borderRadius:32,
    boxShadow:'0 3px 8px 0 rgba(82, 91, 115, .12)',
  },
  secondary_root:{
    background: fade(blueGrey[50],1),
  },
  button:{
    paddingTop:8,
    paddingBottom:8,
    width:100,
    '&:hover':{
      cursor:'pointer'
    }
  },
  firstButton:{
  },
  lastButton:{
  },
  selectedButton:{
    background:theme.palette.primary.main,
    padding:10,
    borderRadius:32,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  }
})

export default withStyles(styles)(SmallNavigation)