import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import MasterTableA from '../../../components/Tables/MasterTableA'
import { request_users } from './requests';
import { Fab, Icon } from '@material-ui/core';

class Users extends Component {

  state = {
    isloading:false,
    users:[],
    table_config:{
      rows:10,
      page:0,
      count:0,
      filter_selected:null,
      search_text:null,
    },
    modals:{
      record_modal:{open:false,issending:false,error:null},
    },
  }

  render(){

    const {classes, language} = this.props
    const {isloading, table_config, users} = this.state
    const content = contentData[language]

    let temp = Object.keys(users).map((item,key)=>{
      let availability = null
      let availability_id = null

      switch(users[item].last_record_type_id){
        case 1:
        case 4:
        case 6:
            availability = 'Disponible'
            availability_id = 1
            break;
        case 2:
            availability = 'Desconectado'
            availability_id = 3
            break;
        case 3:
        case 5:
            availability = 'Ocupado'
            availability_id = 2
            break;
        default:
          break;
      }

      return {
        ...JSON.parse(JSON.stringify(users[item])),
        availability:availability,
        availability_id:availability_id
      }
    })
    console.log(temp)

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
      >
        <div className={classes.root}>
          <Fab className={classes.button} color='primary' onClick={this.onAddUser}>
            <Icon >person_add</Icon>
          </Fab>
          <MasterTableA 
            data={temp}
            table={content.table} 
            config={table_config}
            onSelectedRow={this.onSelectedRow}
            onSearch={this.onSearch}
            onChangePageNumber={this.onChangePageNumber}
            onChangePage={this.onChangePage}/>
        </div>
      </BasicView>
    )
  }

  onAddUser = () => {
    this.props.history.push(`/users/add`)
  }

  onSelectedRow = (item) => {
    this.props.history.push(`/users/${item.id_user}`)
  }

  onSearch = (value) => {
    let temp = {...this.state.table_config}
    temp.search_text = value
    this.setState({table_config:{...temp}})
  }

  onChangePageNumber = (number) => {
    let temp = {...this.state.table_config}
    temp.rows = number
    temp.page = 0
    this.setState({table_config:{...temp}})
  }

  onChangePage = (number) => {
    let temp = {...this.state.table_config}
    temp.page = number
    this.setState({table_config:{...temp}})
  }

  componentDidMount(){
    const data = localStorage.getItem('data')
    if(data){
      const user_crendentials = JSON.parse(data)
      if(user_crendentials.user_type_id > 1){
        this.props.history.push('/')
      }else{
        this.initModule()
      }
    }else{
      this.props.history.push('/signin')
    }
  }

  initModule = async() => {
    const {table_config} = this.state
    this.setState({isloading:true})
    try {
      let response = await request_users({
        limit:table_config.rows,
        offset:table_config.rows*table_config.page
      })
      this.setState({users:[...response.users]})
      this.updateTableConfig('count',response.count)
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  updateTableConfig = (key, data) => {
    const {table_config} = this.state
    let temp = {...table_config}
    temp[key] = data
    this.setState({table_config:{...temp}})
  }

}

const styles = theme => ({
  root:{
    position:'relative'
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
    zIndex:3
  }
})

export default withStyles(styles)(Users)