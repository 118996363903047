export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Crear usuario',
      navigation:[
        {name:'Usuarios',path:'/users'},
        {name:'Crear',path:'/users/add'},
      ]
    },
    
  }
}