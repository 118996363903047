import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import { Redirect } from "react-router-dom"
import {
  Grid,
  Button,
  withWidth,
  Typography
} from '@material-ui/core';
import {contentData} from './content'
import {formData} from './data'

import logo from '../../assets/logo.png'
import ShadowInputText from '../../components/Inputs/ShadowInputText/ShadowInputText.jsx';
import SimpleLoader from '../../components/Loaders/SimpleLoader.jsx';
import Caption from '../../components/Texts/Caption.jsx';
import { red } from '@material-ui/core/colors';
import { request_login } from './requests'

class SignIn extends Component{

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    isValid: false,
    isloading:false,
    error:null,
  }

  
  render(){
    const {classes} = this.props;
    const {formData, isloading, error} = this.state;
    //const content = contentData['spanish']

    let infoMessage = null

    if(isloading){
      infoMessage = <SimpleLoader />
    }else{
      if(error){
        infoMessage = <Caption text={error} medium color={red[900]}/>
      }
    }

    let contentRedirect = this.onCheckValidToken() ? <Redirect to={'/'} /> : null

    return(
      <div className={classes.root}>
        {contentRedirect}
        <div className={classes.subroot}>
          <div className={classes.card}>
            <div className={classes.cardContainer}>
              <div className={classes.logoContainer}>
                <img src={logo} alt='' className={classes.logo}/>
              </div>
              <div className={classes.container}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Grid container direction='column' alignItems='center'>
                      <Grid item>
                        <Typography variant='h5' className={classes.trionix}>Trionix</Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='h6' className={classes.title}>Attendance</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {Object.keys(formData).map((item,key)=>{
                        return(
                          <Grid key={key.toString()} item xs={12}>
                            <ShadowInputText 
                              data={formData[item]}
                              onChange={this.onInputChange}  
                              onKeyPress={this.onKeyPress}
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button 
                          onClick={this.onSubmit} 
                          className={classes.button} 
                          fullWidth>Acceder</Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container justify='center'>
                          <Grid item>
                            {infoMessage}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount(){
    const {formData} = this.state;
    const content = contentData['spanish'];
    let temp = {...formData};
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    });
    this.setState({formData:{...temp}})
  }

  onKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.onSubmit()
    }
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {formData, isValid} = this.state;
    let data2Send = {};
    
    if(isValid){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
      this.onSignInHandler(data2Send)
    }else{
      let temp = {...formData}
      Object.keys(formData).forEach((item)=>{
        if(temp[item].isRequired){
          temp[item].isVisited = true
        }
      })
      this.setState({formData:{...temp}})
    }
    
  }

  onSignInHandler = async(data) => {
    this.setState({isloading:true})
    try {
      await request_login(data)
      this.setState({isloading:false, error:null})
      this.props.history.push('/')
    } catch (error) {
      this.setState({error:'Credenciales inválidas'})
      this.setState({isloading:false})
    }
    
  }

  onCheckValidToken = () => {
    let temp = localStorage.getItem('data');
    let enable_redirect = false
    if(temp){
      temp = JSON.parse(temp);
      let currentTime = new Date();
      let tokenTime = new Date(temp.exp*1000);
      if(currentTime.getTime() < tokenTime.getTime()){
        enable_redirect = true
      }
    }
    return enable_redirect
  }
  
}

export default withStyles(styles)(withWidth()(SignIn));
