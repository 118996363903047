export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Editar usuario',
      navigation:[
        {name:'Usuarios',path:'/user'},
        {name:'Detalles',path:'/user/:id'},
        {name:'Editar',path:'/user/edit/:id'},
      ]
    },
    delete_modal:{
      title:'¿Está seguro?',
      message:'Esta acción no puede ser revertida',
      cancelButton:'Cancelar',
      deleteButton:'Eliminar'
    },
    password_modal:{
      title:'Cambio de contraseña',
      cancel_button:'Cancelar',
      submit_button:'Guardar'
    }
  }
}