import React, {Component} from 'react'
import { withStyles, TableRow, TableCell, Table, TableBody, TableHead } from '@material-ui/core';
import ContentText from '../../Texts/ContentText';
import { grey } from '@material-ui/core/colors';
import GenericCell from './GenericCell';
import { palette } from '../../../variables/config';
import CustomPagination from './CustomPagination';


class TableContent extends Component {
  render(){

    const {classes, header, data, config, nodata, footer_config, onChangePageNumber, onChangePage, onSelectedRow} = this.props

    return(
      <div className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                {header.map((item,key)=>{
                  return(
                    <TableCell 
                      className={classes.cell_row}
                      padding={item.flex ? null : 'checkbox'} 
                      key={key.toString()}>
                      <ContentText text={item.label} medium color={palette.primary.main}/>
                    </TableCell>
                  )
                })}
              </TableRow>
              
            </TableHead>
            <TableBody>
              {data.map((item,key)=>{
                return <TableRow className={classes.row} key={key.toString()} onClick={() => onSelectedRow(item)} >
                    {config.map((el,id)=>{
                      return (
                        <TableCell 
                          padding={el.flex ? 'default' : 'checkbox'} 
                          className={classes.cell} 
                          style={{
                            background: key%2 === 0 ? '#f1f5f8' : 'transparent',
                            borderTopLeftRadius: id === 0 ? 8 : 0,
                            borderBottomLeftRadius: id === 0 ? 8 : 0,
                            borderTopRightRadius: id === config.length-1 ? 8 : 0,
                            borderBottomRightRadius: id === config.length-1 ? 8 : 0,
                          }}
                          key={id.toString()}>                        
                              <GenericCell 
                                avatar={el.image_key ? item[el.image_key] : null}
                                config={el}
                                values={this.onGetValues(item, el.keys)}
                                selected_item={el.id_key ? item[el.id_key] : null}
                                nodata={nodata}
                              />
                            
                        </TableCell>
                            
                      )
                    })}
                  
                </TableRow>
              })}
            </TableBody>
          </Table>
          <div>
            <CustomPagination config={footer_config} onChangePageNumber={onChangePageNumber} onChangePage={onChangePage}/>
          </div>
        </div>
    )
  }

  onGetValues = (data, keys) => {
    let response = []
    if(data){
      response = keys.map(item => {
        return data[item]
      })
    }
    return response
  }

}





const styles = theme => ({
  root:{
    marginTop:16,
    paddingTop:16,
    borderTop:`1px dashed ${grey[300]}`,
    paddingBottom:16,
    marginLeft:16,
    marginRight:16

  },
  table:{
  },
  header:{
    flexGrow:1,
  },
  headcell:{
    paddingLeft:16, 
    paddingRight:16
  },
  cell_body:{
    paddingLeft:16, 
    paddingRight:16,
  },
  cell:{
    padding:16,
    border:0
  },
  cell_row:{
    padding:16,
    border:0
  },
  container:{
    background:'#f1f5f8',
  },
  row:{
    position:'relative',
    marginTop:16,
    marginBottom:16,
    borderRadius:8,
    paddingTop:16,
    paddingBottom:16,
    //background:'#f1f5f8',
    '&:hover':{
      cursor:'pointer'
    },
    //display:'flex',
    //flexDirection:'row',
    //flexGrow:1,
  },
  row_fixed:{
    position:'absolute',
    width:'100%',
    background:'#f1f5f8',
    height:'100%',
    top:-0,
    borderRadius:8,
    zIndex:0
  }
})

export default withStyles(styles)(TableContent)