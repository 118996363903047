import {red, green, grey, amber} from '@material-ui/core/colors';


const styles = theme => ({
  root:{
    display:'flex',
    height:'100vh',
    flexGrow:1,
  },
  subcontainer:{
    margin:'20px',
  },
  iconCheck:{
    color:'green'
  },
  inputContainer:{
    padding:"0px 10px"
  },
  docTitle:{
    fontWeight:'600'
  },
  docContainer:{
    margin:'0px 0'
  },
  container:{
    position: 'relative',
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 32,
  },
  canvas:{
    maxWidth: '150px',
    maxHeight: '150px',
    width: '150px',
    minHeight: '150px',
    borderRadius:'5px',
    background:grey[300],
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    },
  },
  dropzoneContainer:{
    padding:16,
  },
  dropzone:{
    background: grey[300],
    borderRadius:'5px',
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    '&:hover':{
      cursor:'pointer'
    }
  },
  dropzoneIcon:{
    fontSize:'100px',
    padding:8,
    color:grey[500]
  },
  dropzoneText:{

  },
  dropzoneButton:{
    color:'white',
    border:'none',
    width:'inherit',
    background: amber[700],
    textTransform:'uppercase',
    fontSize:'15px',
    fontWeight:'500',
    padding:`6px ${16}px`,
    borderRadius: '25px',
    fontFamily:'Montserrat',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    '&:hover':{
      cursor:'pointer',
      background: amber[900],
    }
  },
  reactdropTitle:{
    fontWeight:'600',
    color: grey[700]
  },
  reactCrop:{
    margin:'auto',
    borderRadius:'5px',
    width: '300px',
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'200px',
    },
  },
  reactcropimage:{
    width:'350px',
    margin:'auto'
  },
  card:{
    paddingBottom: 32,
    width:"100%",
    borderRadius:'10px',
    flexGrow:1,
  },
  cardContent:{
    width:'100%',
    flexGrow:1,
  },
  cardTitle:{
    padding:16,
    color:'white'
  },
  cardTitle2:{
    background:'blue'
  },
  title:{
    background:theme.palette.primary.main
  },
  iconTitle:{
    color:'white'
  },
  titleCardHeader:{
    fontSize:'24px',
    justifyContent:'center',
    fontWeight:'600',
    textTransform:'uppercase'

  },
  avatarHeader:{
    marginLeft:64,
  },
  pictureContainer:{

  },
  navbar:{
    flexGrow: 1,
    width: '100%',
    background: 'transparent',
  },
  tabContainer:{
    padding: '20px',
    borderRadius: '0px 0px 10px 10px',
    background: '#FFF'

  },
  tabRoot:{
    margin:"0px 5px",
    padding:0,
    background:theme.palette.primary.dark,
    backgroundColor:theme.palette.primary.dark,
    borderRadius:'25px',
    color:'white',
    '&:selected': {
      color:'white'
    },
  },
  tabSelected:{
    background:theme.palette.secondary.main,
    color:'white',
  },
  rootTabs:{
    background:'transparent',
    marginBottom:"20px",
    color:'green',
  },
  indicatorSelected:{
    color:'green',
    background:'transparent',

  },
  textColorSecondary:{
    color:'white',
    '&:selected': {
      color:'white'
    },
  },
  colorSwitchBase: {
    color: green[500],
  },
  colorBar: {},
  colorChecked: {},
  missingFields:{
    marginTop:'10px',
    fontSize:'12px',
    fontWeight:'600'
  },
  saveButton:{
    background:green[700],
    borderRadius:'25px',
    color:'white',
    '&:hover':{
      background:green[900],
    }
  },
  cancelButton:{
    background:'red',
    color:'white',
    '&:hover':{
      background:'transparent',
      border:'1px solid red',
      color:'red'
    }
  },
  error:{
    color:red[900],
    fontSize:'0.9em',
    fontWeight:'600'
  },
});

export default styles;
