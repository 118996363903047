import React, {Component} from 'react'
import {withRouter} from 'react-router'
import { Grid, Breadcrumbs, Link, Typography, Icon, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cx from  'classnames'
import { grey, blueGrey } from '@material-ui/core/colors';

const styles = (theme) => ({
  title:{
    color:theme.palette.primary.dark,
    fontWeight:'500',
    minWidth:250
  },
  link:{
    color:grey[500],
    fontWeight:'500',
    fontSize:14,
    '&:hover':{
      cursor:'pointer',
      textDecoration:'none'
    }
  },
  selectedLink:{
    color:theme.palette.primary.dark,
    fontWeight:'500'
  },
  iconbutton:{
    padding:8
  },
  breadcrumbs:{
    background:blueGrey[100],
    padding:8,
    paddingLeft:32,
    paddingRight:32,
    borderRadius:32
  }
})

class NavigationTitle extends Component {

  onNavigation = (path) => {
    let temp = path
    const {history} = this.props
    if(path.includes(':id')){
      const {match} = this.props
      const id = match.params.id
      temp = path.replace(':id', id)
    } 
    history.push(temp)
  }

  render(){

    const {classes, title, navigation, onReturn} = this.props;

    let navigationContent = null
    let returnContent = null

    if(navigation){
      navigationContent = navigation.map((item,key)=> {
        return(
          <Link 
            className={cx({
              [classes.link]:true,
              [classes.selectedLink]:key===navigation.length-1,
            })}
            onClick={() => this.onNavigation(item.path)} 
            key={key.toString()}
          >
            {item.name}
          </Link>
        )
      })
    }

    if(onReturn){
      returnContent = (
        <Grid item>
          <IconButton 
            className={classes.iconbutton} 
            color='primary'
            onClick={onReturn}
          >
            <Icon>arrow_back</Icon>
          </IconButton>
        </Grid>
      )
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
              {returnContent}
              <Grid item xs>
                <Typography variant='h5' className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <div className={classes.breadcrumbs}>
              <Breadcrumbs>
                {navigationContent}
              </Breadcrumbs>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
  
}

export default withRouter(withStyles(styles)(NavigationTitle))