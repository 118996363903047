import React, {Component} from 'react'
import { withStyles, AppBar, Toolbar, Grid, IconButton, Icon } from '@material-ui/core';
import cx from 'classnames'

import {layout} from '../../../variables/config';
import UserButton from '../../../components/Buttons/UserButton/UserButton';


class Topbar extends Component {
  render(){

    const {classes, user, onChangePosition, onChangeRoute, onLogout, open} = this.props

    return(
      <div>
        <AppBar
          position='fixed'
          className={cx({
            [classes.appbar]:true,
            [classes.closed_appbar]:!open
          })}
        >
          <Toolbar>
            <Grid container alignItems='center'>
              <Grid item>
                <IconButton size='small' onClick={onChangePosition}>
                  <Icon className={classes.icon}>menu</Icon>
                </IconButton>
              </Grid>
              <Grid item xs/>
              <Grid item>
                <UserButton user={user} onMenuSelected={onChangeRoute} onLogout={onLogout}/>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        
      </div>
    )
  }
}

const styles = (theme) => ({
  root:{},
  appbar:{
    background:theme.palette.primary.main,
    left:layout.width,
    width:`calc(100% - ${layout.width+16}px)`,
    borderRadius:16,
    marginLeft:8,
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.only('md')]: {
      width:`calc(100% - 16px)`,
      left:0,
    },
    [theme.breakpoints.down('sm')]: {
      width:`calc(100% - 16px)`,
      marginLeft:8,
      left:0
    },
  },
  closed_appbar:{
    width:`calc(100% - 16px)`,
    left:0,
    transition: theme.transitions.create(['width', 'left'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  icon:{
    color:'white'
  }
})

export default withStyles(styles)(Topbar)