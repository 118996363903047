import React, { Component } from 'react';
import BasicView from '../../../components/Layouts/BasicView/BasicView';
import { contentData } from './content';
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../../../components/Cards/CardWrapper/CardWrapper';
import ProfileForm from './components/ProfileForm/ProfileForm';
import { request_add_user } from './requests';


class AddUser extends Component {

  state = {
    isloading:false,
    issending:false,
    error:null,
    user:null,
  }

  render(){

    const {classes, language, catalogs} = this.props
    const {user, isloading, issending, error} = this.state

    const content = contentData[language]

    return(
      <BasicView
        isloading={isloading}
        content={content.layout}
        onReturn={this.onReturn}
      >
        <div>
          <Grid container>
            <Grid item xs={12}>
              <CardWrapper>
                <div className={classes.root}>
                  <ProfileForm 
                    user={user}
                    language={language} 
                    catalogs={catalogs} 
                    isloading={issending}
                    error={error}
                    onSubmit={this.onSubmit}
                    onCancel={this.onReturn}/>
                </div>
              </CardWrapper>
            </Grid>
            
          </Grid>
        </div>

      </BasicView>
    )
  }

  componentDidMount(){
    // check permissions
    const data = localStorage.getItem('data')
    if(data){
      const user_crendentials = JSON.parse(data)
      if(user_crendentials.user_type_id > 1){
        this.props.history.push('/')
      }
    }else{
      this.props.history.push('/signin')
    }
    
  }

  onSubmit = async(data) => {
    if(data){
      if(JSON.stringify(data) !== "{}"){
        this.setState({issending:true})
        try {
          let response = await request_add_user(data)
          console.log(response)
          this.props.history.push('/users')
        } catch (error) {
          if(error.response){
            this.setState({issending:false})
            let e = {...error.response.data.error}
            this.setState({error:`#${e.code}, ${e.message}`})
          }else{
            this.setState({error:`No Internet`})
          }
          
        }
        
      }
    }
  }

  onReturn = () => {
    this.props.history.push('/users')
  }


}

const styles = theme => ({
  root:{
    padding:32
  }
})

export default withStyles(styles)(AddUser)