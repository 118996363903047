import React from 'react'
import { makeStyles, Grid, Avatar, Icon } from '@material-ui/core';
import ContentText from '../../Texts/ContentText';
import nouser from '../../../assets/nouser.png'
import { grey } from '@material-ui/core/colors';

const imageURL = 'https://trionix-management-public.s3.amazonaws.com/'

const useStyles = makeStyles(theme => ({
  root:{},
  avatar:{

  },
  icon:{
    color:theme.palette.primary.main,
    paddingTop:4
  }
}))


const GenericCell = props => {

  const classes = useStyles()

  const {values, config, avatar, nodata, selected_item} = props

  let label = onJoinData(values)

  let iconContent = null
  if(config){
    switch (config.type) {
      case 'avatar':
        let avatarContent = null
        if(avatar){
          if(avatar !== "" && avatar !== "0"){
            avatarContent = <Avatar src={imageURL + avatar} alt='' className={classes.avatar}/>
          }else{
            avatarContent = <Avatar src={nouser} alt='' className={classes.avatar}/>
          }
        }else{
          avatarContent = <Avatar src={nouser} alt='' className={classes.avatar}/>
        }
        iconContent = (
          <Grid item>
            {avatarContent}
          </Grid>
        )
        break;
      case 'data':
        if(config.icon){
          let icon  = {...config.icon}
          iconContent = (
            <Grid item>
              <Icon fontSize='small' className={classes.icon}>{icon.name}</Icon>
            </Grid>
          )
        }
        break;
      case 'status':
        iconContent = (
          <Grid item>
            <Icon style={{fontSize:12, color:selected_item ? config.colors[selected_item-1] : grey[400]}}>brightness_1</Icon>
          </Grid>
        )
        break;
      default:
        break;
    }
  }

  return(
      <div >
        <Grid container alignItems='center' spacing={1} wrap='nowrap'>
          {iconContent}
          <Grid item xs>
            <ContentText text={label !== '' ? label : nodata} medium variant={label === '' ? 'light' : null}/>
          </Grid>
        </Grid>
      </div>
  )
}

const onJoinData = (values) => {
  let response = ''
  values.forEach((item, key) => {
    if(!key){
      response += item ? item : ''
    }else{
      response += item ? (' ' + item) : ''
    }
  })
  return response
}

export default GenericCell