import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../Cards/CardWrapper/CardWrapper';
import TableMenu from './componentes/TableMenu'
import SearchBar from './componentes/SearchBar'
import TableContent from './componentes/TableContent'



class MasterTableA extends Component {
  render(){

    const {table, config, data, onChangePageNumber, onChangePage, onSearch, onSelectedRow} = this.props
    //console.log(data)
    return(
      <CardWrapper>
        <div>
          <div style={{padding:16}}>
            <Grid container alignItems='center'>
              <Grid item>
                <TableMenu selected={1} options={table.filter_list} onItemSelected={this.onFilterSelected}/>
              </Grid>
            </Grid>
          </div>
          <div>
            <SearchBar onSearch={onSearch}/>
          </div>
          <div>
            <TableContent 
              footer_config={config}
              header={table.header} 
              data={data} 
              config={table.table_row} 
              nodata={table.nodata}
              onSelectedRow={onSelectedRow}
              onChangePageNumber={onChangePageNumber}
              onChangePage={onChangePage}/>
          </div>
        </div>
      </CardWrapper>
    )
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(MasterTableA)