import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import {formData} from './data'
import {contentData} from './content'

import ShadowInputText from '../../../../../components/Inputs/ShadowInputText/ShadowInputText'
import ShadowInputSelect from '../../../../../components/Inputs/ShadowInputSelect/ShadowInputSelect'
import { SecondRoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import SimpleMenu from '../../../../../components/Navigation/SimpleMenu';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import ContentText from '../../../../../components/Texts/ContentText';
import { red } from '@material-ui/core/colors';

const filter_form = [
  ['username','user_type_id','first_name','last_name','gender_id','employee_status_id'],
  ['position','office_id','area_id','supervisor_id','start_hour','end_hour'],
  ['work_email','work_phone'],
]

class ProfileForm extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    selected:0,
  }

  render(){

    const {onCancel, language, isloading, error, user} = this.props
    const {formData, selected} = this.state

    const content = contentData[language]

    let formContent = null
    
    const disabled_items = user ? user.user_type_id > 1 : false

    formContent = Object.keys(formData).map((item,key)=>{

      if(filter_form[selected].find(el => el === item)){
        let inputContent = null
        switch (formData[item].config.type) {
          case 'select':
            inputContent = (
              <ShadowInputSelect 
              disabled={disabled_items}
                data={formData[item]}
                onChange={this.onInputChange}/>
            )
            break;
          
          default:
            inputContent = (
              <ShadowInputText 
              disabled={disabled_items}
              data={formData[item]} 
              onChange={this.onInputChange}/>
            )
            break;
        }
        return(
          <Grid item xs={12} md={6} key={key.toString()}>
            {inputContent}
          </Grid>
        )
      }else{
        return null
      }
      
    })

    let infoContent = null

    if(isloading){
      infoContent = <SimpleLoader />
    }else{
      if(error){
        infoContent = <ContentText text={error} medium color={red[700]}/>
      }
    }

    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SimpleMenu 
                  selected={selected}
                  options={content.menu_form}
                  onChange={this.onChangeTab}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  {formContent}
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justify='flex-end' alignItems='center'>
              <Grid item>
                {infoContent}
              </Grid>
              <Grid item>
                <SecondRoundedButton label='Cancelar' size='small' color='grey' onClick={onCancel}/>
              </Grid>
              <Grid item>
                <SecondRoundedButton label='Guardar' size='small' onClick={this.onSubmit}/>
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
        
      </div>
    )
  }

  componentDidMount(){
    const {user, language, catalogs} = this.props
    const {formData} = this.state
    const content = contentData[language]
    let temp = {...formData}
    //Setting catalogs
    temp['user_type_id'].options = catalogs['user_types'] ? [...catalogs['user_types']] : []
    temp['gender_id'].options = catalogs['genders'] ? [...catalogs['genders']] : []
    temp['employee_status_id'].options = catalogs['employee_statuses'] ? [...catalogs['employee_statuses']] : []
    temp['office_id'].options = catalogs['offices'] ? [...catalogs['offices']] : []
    temp['area_id'].options = catalogs['areas'] ? [...catalogs['areas']] : []
    temp['supervisor_id'].options = catalogs['supervisors'] ? [...catalogs['supervisors']] : []


    Object.keys(formData).forEach((item) => {
      if(user){
        let value = user[item];
        temp[item].value = value ? value : '';
        temp[item].isVisited = value ? true : false;
        temp[item].isValid = value ? true : false;
      } // update data
      temp[item].config = {...temp[item].config,...content.form[item]};
    })
    
    this.setState({formData:{...temp}})
  }

  onChangeTab = (id) => {
    this.setState({selected:id})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, user} = this.props;
    const {formData} = this.state;
    let temp = {...formData}

    let isFormGood = true
    Object.keys(formData).forEach(item => {
      if(temp[item].isRequired){
        if(!temp[item].isValid){
          isFormGood = false
          temp[item].isVisited = true
        }
      }
    })

    if(isFormGood){
      let data2Send = {};
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(user){
            if(formData[item].value !== user[item]){
              data2Send = {...data2Send, [item]:formData[item].value}
            }
          }else{
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
      onSubmit(data2Send)
    }else{
      this.setState({formData:{...temp}})
    }
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(ProfileForm)