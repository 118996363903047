export const contentData = {
  spanish:{
    form:{
      record_type_id:{
        label:'Tipo de registro',
        placeholder:'Seleccione...',
        helper:'Debe de seleccionar una opción'
      },
      notes:{
        label:'Notas',
        placeholder:'Notas (opcional)',
        helper:'Nota no válido'
      },
    }
  }
}