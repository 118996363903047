import React, { Component } from 'react';
import { withStyles, Grid, Icon, IconButton } from '@material-ui/core';
import ContentText from '../../Texts/ContentText';
import GenericPopper from '../../Poppers/GenericPopper';

const page_catalogs = [
  {id:1, label:'10'},
  {id:2, label:'25'},
  {id:3, label:'50'},
  {id:4, label:'100'},
]

class CustomPagination extends Component {

  state = {
    open:false,
    anchorEl:null,
    rows:10,
    page:0,
    count:101,
    pages:[10,25,50,100]
  }

  render(){

    const {classes, config} = this.props
    const {rows,page,count, open, anchorEl} = this.state

    let idx_start = page*rows + 1
    let idx_end = page*rows + rows 
    let r_count = count
    let r_page = page

    if(config){
      idx_start = config.page*config.rows + 1
      idx_end = config.page*config.rows + config.rows 
      r_count = config.count
      r_page = config.page
    }

    return(
      <div className={classes.root}>
        <GenericPopper
          open={open}
          anchorEl={anchorEl}
          onClickAway={this.onClose}
          onSelectedItem={this.onSelectedItem}
          menu={[...page_catalogs]}
        />
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <Grid container alignItems='center' spacing={0}>
              <Grid item>
                <ContentText text={'No. filas'} medium/>
              </Grid>
              <Grid item>
                <div className={classes.row_button} onClick={this.onOpen}>
                  <Grid container alignItems='center'>
                    <Grid item><div style={{paddingLeft:8}}><ContentText text={rows.toString()} medium variant='bold'/></div></Grid>
                    <Grid item><Icon style={{paddingTop:4}}>arrow_drop_down</Icon></Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <ContentText text={`${idx_start}-${idx_end} de ${r_count}`} medium/>
          </Grid>
          <Grid item>
            <Grid container alignItems='center'>
              <Grid item><IconButton onClick={() => this.onChange('max_left')}size='small'><Icon>first_page</Icon></IconButton></Grid>
              <Grid item><IconButton onClick={() => this.onChange('left')}size='small'><Icon>chevron_left</Icon></IconButton></Grid>
              <Grid item><div style={{paddingLeft:8,paddingRight:8}}><ContentText text={r_page+1} medium variant='bold'/></div></Grid>
              <Grid item><IconButton onClick={() => this.onChange('right')}size='small'><Icon>chevron_right</Icon></IconButton></Grid>
              <Grid item><IconButton onClick={() => this.onChange('max_right')}size='small'><Icon>last_page</Icon></IconButton></Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  onSelectedItem = (item) => {
    this.setState({rows: parseInt(item.label)})
    this.onClose()
    this.props.onChangePageNumber(parseInt(item.label))
  }

  onOpen = (event) => {
    this.setState({
      open:true,
      anchorEl:event.currentTarget
    })
  }

  onClose = () => {
    this.setState({
      open:false,
      anchorEl:null
    })
  }

  onChange = (type) => {
    const {rows,page,count} = this.state
    const {config} = this.props


    let r_rows = rows
    let r_count = count
    let r_page = page

    if(config){
      r_rows = config.rows
      r_count = config.count
      r_page = config.page
    }

    const last_page = Math.ceil(r_count/r_rows)
    
    let temp = r_page
    switch(type){
      case 'max_left':
        temp = 0
        break;
      case 'left':
        temp--
        break;
      case 'right':
        temp++ 
        break;
      case 'max_right':
        temp = last_page - 1
        break
      default:
        break;
    }

    if(temp < 0) temp = 0
    if(temp > last_page - 1) temp = last_page - 1
    this.setState({page:temp})
    this.props.onChangePage(temp)
    //console.log(type)
  }

}

const styles = theme => ({
  root:{marginTop:16},
  row_button:{
    borderRadius:8,
    '&:hover':{
      background:'rgba(0,0,0,0.1)',
      cursor:'pointer'
    }
  }
})

export default withStyles(styles)(CustomPagination)