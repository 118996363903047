import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import Sidebar from './components/Sidebar';
import TopBar from './components/Topbar';
import { Switch, Route, Redirect } from "react-router-dom"
import cx from 'classnames'

import routes from '../../routes/dashboard'
import { layout } from '../../variables/config';
import {catalogs as catalogContent}  from '../../variables/catalogs'
import { withWidth } from '@material-ui/core';
import {request_reauth, request_me} from './requests'

import moment from 'moment-timezone'


class Dashboard extends Component {

  state={
    open:true,
    user:null,
    isreauth:false,
    language:'spanish'
  }

  render(){

    const {classes, history} = this.props
    const {open, language, user} = this.state

    let catalogs = catalogContent[language]
    const timezones = moment.tz.names().map(item => ({value:item, label:item}))
    catalogs.timezones = [...timezones]
    /* ENABLE REDIRECTION */
    const must_redirect = this.onCheckValidToken()
    const redirectComponent = must_redirect ? <Redirect to={'/signin'}/> : null
    

    return(
      <div className={classes.root}>
        {redirectComponent}
        <Sidebar 
          history={history} 
          user={user} 
          open={open} 
          onClose={this.onClose} 
          routes={routes}
          onChange={this.onChangeRoute}/>
        <TopBar 
          user={user} 
          open={open} 
          onChangePosition={this.onChangePosition}
          onChangeRoute={this.onChangeRoute}
          onLogout={this.onLogout}/>
        <div className={cx({
          [classes.main]:true,
          [classes.closed_main]:!open
        })}>
          <Switch>
            {routes.sections.map((route,idx) => {
              if(route.redirect)
                return <Redirect to={route.to} key={idx.toString()}/>
              return <Route 
                path={route.path} 
                exact={route.exact}
                render={(props) => <route.component 
                  {...props} 
                  language={'spanish'} 
                  parentuser={user} 
                  catalogs={catalogs}
                  user_level={null}
                  permissions={route.level}
                  onChangeParentUser={this.onChangeParentUser}
                />} 
                key={idx.toString()}
              />
            })
            }
          </Switch>
        </div>
      </div>
    )
  }

  componentWillUnmount(){
    clearInterval(this.timer)
  }

  componentDidMount(){
    let isRedirect = this.onCheckValidToken()
    if(!isRedirect){
      this.timer = setInterval(this.onCheckToken, 2000)
      this.initModule()
    }
  }

  initModule = async() => {
    try {
      let response = await request_me()
      this.setState({user:{...response}})
    } catch (error) {
      console.log(error)
    }
  }

  onCheckToken = async() => {
    try {
      const {history} = this.props;
      const {isreauth} = this.state;
      const data = JSON.parse(localStorage.getItem('data')) 
      if(data && !isreauth){
        let expDate = new Date(data.exp*1000)
        let currentDate = new Date()
        if(currentDate.getTime() < expDate.getTime()){ // valid token
          if((currentDate.getTime() + 15*60*1000) > expDate.getTime()){ // expiration date soon 
            this.setState({isreauth:true})
            await request_reauth()
            this.setState({isreauth:false})
          }
        }else{
          localStorage.clear();
          history.push("/signin");
        }
      }else{
        localStorage.clear();
        history.push("/signin");
      }
    } catch (error) {
    }
  }

  onChangePosition = () => {
    this.setState( prevState => ({
      open: !prevState.open
    }))
  }

  onChangeRoute = (url) => {
    const {history, width} = this.props;
    history.push(url)
    if(width === 'xs' || width === 'sm' || width === 'md'){
      this.onClose()
    }
  }

  onLogout = () => {
    localStorage.clear();
    this.props.history.push("/signin");
  }

  onCheckValidToken = () => {
    const {history} = this.props
    let temp = localStorage.getItem('data');
    let enable_redirect = false
    if(temp){
      temp = JSON.parse(temp);
      let currentTime = new Date();
      let tokenTime = new Date(temp.exp*1000);

      if(currentTime.getTime() > tokenTime.getTime()){
        
        localStorage.setItem('preurl',history.location.pathname);
        enable_redirect = true
      }else{
        localStorage.removeItem('preurl')
      }
    }else{
      localStorage.setItem('preurl',history.location.pathname);
      enable_redirect = true
    }

    return enable_redirect
  }

  onClose = () => {
    this.setState({open:false})
  }
}

const styles = (theme) => ({
  root:{
    position:'relative',
    flexGrow:1,
    width:'100%',
    height:'100vh',
    background:'#f1f5f8',
    overflow:'hidden',
    display: 'flex',
  },
  main:{
    flexGrow:1,
    width:`calc(100% - ${layout.width}px)`,
    boxSizing:'border-box',
    marginTop:'66px',
    marginLeft:layout.width,
    display: 'flex',
    padding:32,
    paddingRight:0,
    paddingBottom:0,
    paddingTop:0,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.only('md')]: {
      marginLeft:0,
      width:`100%`,
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft:0,
      width:`100%`,
      padding:16,
      paddingRight:0,
      paddingTop:0,
      paddingBottom:0,
    },
    
  },
  closed_main:{
    marginLeft:0,
    width:`100%`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }
})

export default withStyles(styles)(withWidth()(Dashboard))