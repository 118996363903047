import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import BasicView from '../../../components/Layouts/BasicView/BasicView'
import {contentData} from './content'
import { Grid } from '@material-ui/core';
import ProfileCard from './components/ProfileCard';

import AttendanceCard from './components/AttendanceCard';
import { calendar } from '../../../variables/config';
import TimeCard from './components/TimeCard';

import entrance_hour_image from '../../../assets/log-in.png'
import exit_hour_image from '../../../assets/exit.png'

import regular_image from '../../../assets/punch.png'
import injury_image from '../../../assets/crutch.png'
import vacations_image from '../../../assets/plane.png'
import dismissed_image from '../../../assets/alarm.png'
import complain_image from '../../../assets/complain.png'
import StatusCard from './components/StatusCard';
import { request_user, request_records } from './request';

const status_img_catalog = [
  regular_image,
  injury_image,
  vacations_image,
  dismissed_image,
  complain_image
]

class UserView extends Component {

  state = {
    isloading:false,
    user:null,
    employee_records:[],
    modals:{
      record_modal:{open:false,issending:false,error:null},
    },
  }
  
  render(){

    const {classes,language} = this.props
    const {user, employee_records, isloading} = this.state
    const content = contentData[language]
    const months = calendar[language].short_months

    let profile_content = this.onUpdateFormData(content.profile_card.form, user, content.nodata)
    let start_hour = '00:00'
    let end_hour = '00:00'
    let status = ''
    let status_image
    if(user){
      start_hour = user.start_hour ? user.start_hour : '00:00'
      end_hour = user.end_hour ? user.end_hour : '00:00'
      status = user.employee_status ? user.employee_status : 'Sin registro'
      status_image = user.employee_status_id ?  status_img_catalog[user.employee_status_id-1] : status_img_catalog[4] 
    }

    return(
      <BasicView
        content={content.layout}
        isloading={isloading}
        onReturn={this.onReturn}
      >
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <Grid item container spacing={3}>
                <Grid item xs={12}>
                  <ProfileCard form={profile_content} user={user} onEdit={this.onEdit}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TimeCard 
                    title='Hora de entrada'
                    time={start_hour}
                    image={entrance_hour_image}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TimeCard 
                    title='Hora de salida'
                    time={end_hour}
                    image={exit_hour_image}/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StatusCard 
                    title='Estatus'
                    status={status}
                    image={status_image}/>
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12} md={4}>
              <AttendanceCard 
                months={months}
                data={employee_records} 
                content={content.attendance_card} 
                onViewDetails={this.onGo2AttendanceList}/>
            </Grid>
          </Grid>
        </div>
      </BasicView>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  onReturn = () => {
    this.props.history.push('/users')
  }

  initModule = async() => {
    this.setState({isloading:true})
    try {
      const {id} = this.props.match.params
      let response = await request_user(id)
      this.setState({user:JSON.parse(JSON.stringify(response))})
      response = await request_records(id)
      this.setState({employee_records:response ? [...response]:[]})
    } catch (error) {
      console.log(error)
    }
    this.setState({isloading:false})
  }

  onEdit = () => {
    const {id} = this.props.match.params
    this.props.history.push(`/users/edit/${id}`)
  }

  onGo2AttendanceList = () => {

  }

  onUpdateFormData = (form, data, nodata) => {
    let temp = {...form}
    Object.keys(temp).forEach(item => {
      let value = nodata
      if(data){
        if(data[item]){
          value = data[item]
        }
      }
      temp[item].value = value
    })
    return temp
    
  }

  onOpenModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(data){
      tempModals[section].data = {...data} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

const styles = (theme) => ({
  root:{
    position:'relative',
    
  },
  button:{
    position:'fixed',
    bottom:32,
    right:32,
    [theme.breakpoints.down('sm')]: {
      bottom:16,
      right:16,
    },
  }
})

export default withStyles(styles)(UserView)