export const contentData = {
  spanish:{
    nodata:'Sin registro',
    layout:{
      title:'Mi perfil',
      navigation:[
        {name:'Mi perfil',path:'/me'},
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
    },
    register_modal:{
      title:'Registrar evento',
      cancel_button:'Cancelar',
      submit_button:'Registrar'
    },
    attendance_card:{
      nodata:'Sin registro'
    },
    profile_card:{
      form:{
        username:{
          label:'Usuario'
        },
        user_type:{
          label:'Tipo de usuario'
        },
        first_name:{
          label:'Nombre(s)'
        },
        last_name:{
          label:'Apellido(s)'
        },
        gender:{
          label:'Género'
        },
        status:{
          label:'Estatus'
        },
        work_email:{
          label:'E-mail de trabajo'
        },
        work_phone:{
          label:'Teléfono de trabajo'
        },
        position:{
          label:'Puesto'
        },
        employee_status:{
          label:'Regular'
        },
        office:{
          label:'Oficina'
        },
        area:{
          label:'Área'
        },
        supervisor:{
          label:'Supervisor'
        },
        start_hour:{
          label:'Hora de entrada',
        },
        end_hour:{
          label:'Hora de salida'
        }
      }
    }
  }
}