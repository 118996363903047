export const formData = {
  username:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'username',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  user_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'user_type_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  password:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password',
      type:'password',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:8,
      max:254
    }
  },
  password_confirmation:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password_confirmation',
      type:'password',
      fullWidth: true,
    },
    rules:{
      type:'equals',
    }
  },
  first_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'first_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  last_name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'last_name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  gender_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'gender_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  timezone:{
    value: 'America/Mexico_City',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    options:[],
    config:{
      id:'timezone',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  employee_status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'employee_status_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  employee_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'employee_type_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  position:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'position',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  
  office_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'office_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  area_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'area_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  supervisor_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'supervisor_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  start_hour:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'start_hour',
      type:'time',
      fullWidth: true,
    },
    rules:{
      type:'time',
    }
  },
  end_hour:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'end_hour',
      type:'time',
      fullWidth: true,
    },
    rules:{
      type:'time',
    }
  },
  
  work_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'work_email',
      type:'email',
      fullWidth: true,
    },
    rules:{
      type:'email',
    }
  },
  work_phone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'work_phone',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'phone_number',
    }
  },
}