export const contentData = {
  spanish:{
    form:{
      password:{
        label:'Contraseña nueva',
        placeholder:'Contraseña nueva',
        helper:'Contraseña no válida'
      },
    }
  }
}