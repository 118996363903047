import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Tab, Tabs, withWidth } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';


const CustomTabs = withStyles({
  root: {
    backgroundColor:blueGrey[50],
    borderRadius:8,
  },
  indicator: {
    backgroundColor: 'transparent',
  },
})(Tabs);

const CustomTab = withStyles(theme => ({
  root: {
    fontWeight:500,
    '&:hover': {
      //color: theme.palette.primary.main,
      //opacity: 1,
    },
    '&$selected': {
      //color: '#1890ff',
      //color: theme.palette.primary.main,
      //fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      //color: '#40a9ff',
    },
  },
  selected: {
    background:theme.palette.primary.main,
    color:'white',
    fontWeight:500,
    borderRadius:8,
  },
}))(Tab);


class SimpleMenu extends Component {
  render(){

    const {width, onChange, options, selected} = this.props

    return(
      <div>
        <CustomTabs
          variant={width !== 'sm' && width !== 'xs' ? 'fullWidth' : 'scrollable'}
          scrollButtons="auto"
          value={selected}
          onChange={(e,id) => onChange(id)}
        > 
          {options.map((item,key)=>{
            return(
              <CustomTab label={item.label} key={key.toString()}/>
            )
          })}
        </CustomTabs>   
      </div>
      
    )
  }
  
}

const styles = theme => ({
  container:{
    overflowY:'hidden',
    overflowX:'auto'
  },
  root:{
    background: fade('#FFF',1),
    borderRadius:32,
    boxShadow:'0 3px 8px 0 rgba(82, 91, 115, .12)',
  },
  secondary_root:{
    background: fade(blueGrey[50],1),
  },
  button:{
    paddingTop:8,
    paddingBottom:8,
    width:100,
    '&:hover':{
      cursor:'pointer'
    }
  },
  firstButton:{
  },
  lastButton:{
  },
  selectedButton:{
    background:theme.palette.primary.main,
    padding:10,
    borderRadius:32,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
  }
})

export default withStyles(styles)(withWidth()(SimpleMenu))