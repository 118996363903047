export const contentData = {
  spanish:{
    menu_form:[
      {id:0, label:'General'},
      {id:1, label:'Laboral'},
      {id:2, label:'Contacto'},
    ],
    form:{
      username:{
        label:'Usuario',
        placeholder:'Usuario',
        helper:'Usuario no válido'
      },
      user_type_id:{
        label:'Tipo de usuario',
        placeholder:'Tipo de usuario',
        helper:'Debe de seleccionar una opción'
      },
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)',
        helper:'Nombre no válido'
      },
      last_name:{
        label:'Apellido(s)',
        placeholder:'Apellido(s)',
        helper:'Apellido no válido'
      },
      gender_id:{
        label:'Género',
        placeholder:'Género',
        helper:'Debe de seleccionar una opción'
      },
      status:{
        label:'Estatus',
        placeholder:'Estatus',
        helper:'Estatus no válido'
      },
      work_email:{
        label:'E-mail de trabajo',
        placeholder:'E-mail de trabajo',
        helper:'E-mail no válido'
      },
      work_phone:{
        label:'Teléfono de trabajo',
        placeholder:'Teléfono de trabajo',
        helper:'Teléfono no válido'
      },
      position:{
        label:'Puesto',
        placeholder:'Puesto',
        helper:'Posición no válida'
      },
      employee_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
        helper:'Debe de seleccionar una opción'
      },
      office_id:{
        label:'Oficina',
        placeholder:'Oficina',
        helper:'Debe de seleccionar una opción'
      },
      area_id:{
        label:'Área',
        placeholder:'Área',
        helper:'Debe de seleccionar una opción'
      },
      supervisor_id:{
        label:'Supervisor',
        placeholder:'Supervisor',
        helper:'Debe de seleccionar una opción'
      },
      start_hour:{
        label:'Hora de entrada',
        placeholder:'Hora de entrada',
        helper:'Hora no válida'
      },
      end_hour:{
        label:'Hora de salida',
        placeholder:'Hora de salida',
        helper:'Hora no válida'
      }
    }
  }
  
}