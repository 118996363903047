export const contentData = {
  spanish:{
    form:{
      username:{
        label:'Usuario',
        placeholder:'Usuario',
        helper:'Introduzca un usuario válido'
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
        helper:'Introduzca una contraseña válida'
      }
    },
    error:'Usuario/Contraseña incorrectos',
    button:{label:'Ingresar'}
  }
}