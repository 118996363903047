import React, {Component} from 'react'
import { withStyles, Grid } from '@material-ui/core';
import CardWrapper from '../../../../components/Cards/CardWrapper/CardWrapper';
import ProfileAvatar from '../../../../components/Avatars/ProfileAvatar'
import VerticalDataWrapper from '../../../../components/Texts/VerticalDataWrapper';
import ContentText from '../../../../components/Texts/ContentText';
import { palette } from '../../../../variables/config';

class ProfileCard extends Component {
  render(){
    const {classes, user, form, onEdit} = this.props

    return(
      <div>
        <CardWrapper
          title='Mi información'
          link='Editar'
          onClickLink={onEdit}
        >
          <div className={classes.root}>
            <Grid container spacing={4}>
              <Grid item xs={12} >
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <Grid container justify='center'>
                      <Grid item><ProfileAvatar user={user} image={user ? user.image : null}/></Grid>
                    </Grid>
                    
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.first_name.label} 
                          text={form.first_name.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.last_name.label} 
                          text={form.last_name.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.username.label} 
                          text={form.username.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.user_type.label} 
                          text={form.user_type.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.position.label} 
                          text={form.position.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.office.label} 
                          text={form.office.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.area.label} 
                          text={form.area.value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.supervisor.label} 
                          text={form.supervisor.value}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ContentText text='Información de contacto' medium color={palette.primary.main} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.work_email.label} 
                          text={form.work_email.value}
                          icon='mail'
                          iconColor={palette.primary.main}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <VerticalDataWrapper 
                          label={form.work_phone.label} 
                          text={form.work_phone.value}
                          icon='phone'
                          iconColor={palette.primary.main}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </CardWrapper>
      </div>
    )
  }
}

const styles = theme => ({
  root:{
    padding:32
  }
})

export default withStyles(styles)(ProfileCard)