export const catalogs={
  spanish:{
    user_types:[{value:1,label:'Administrador'},{value:2,label:'Empleado'},],
    genders:[{value:1,label:'Hombre'},{value:2,label:'Mujer'},{value:3,label:'Otro'},],
    employee_statuses:[{value:1,label:'Regular'},{value:2,label:'Incapacidad'},{value:3,label:'Vacaciones'},{value:4,label:'Baja'},],
    employee_types:[{value:1,label:'Servicio Social'},{value:2,label:'Medio Tiempo'},{value:3,label:'Tiempo Completo'},],
    record_types:[{value:1,label:'Inicio de Jornada'},{value:2,label:'Fin de Jornada'},{value:3,label:'Inicio de Descanso'},{value:4,label:'Fin de Descanso'},{value:5,label:'Inicio de Comida'},{value:6,label:'Fin de Comida'},],
    offices:[{value:1, label:'CDMX'}],
    areas:[{value:1, label:'General'}],
    supervisors:[{value:1,label:'General'}]
  },
}