export const formData = {
  password:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password',
      type:'password',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
}