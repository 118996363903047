export const formData = {
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'select',
    }
  },
  description:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'description',
      type:'text',
      fullWidth: true,
      multiline:true,
      rows:3
    },
    rules:{
      type:'distance',
      min:1,
      max:254
    }
  },
}