import {mainServer} from '../../../variables/config'

export const request_user = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/user/${id}`)
  return response.data.data.user
}

export const request_records = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/employeerecord/${id}/list`, 
  {params:{
    limit:20,
    offset:0,
    order:1,
    order_by:'timestamp'
  }})
  return response.data.data.employee_records
}

export const request_add_employee_record = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.post("/employeerecord/me", data)
  let response
  response = await mainServer.instance.get("/employeerecord/me/list", 
  {params:{
    limit:20,
    offset:0,
    order:1,
    order_by:'timestamp'
  }})
  return response.data.data.employee_records
}