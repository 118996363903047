import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import {formData} from './data'
import {contentData} from './content'

import ShadowInputText from '../../../../../components/Inputs/ShadowInputText/ShadowInputText'
import ShadowInputSelect from '../../../../../components/Inputs/ShadowInputSelect/ShadowInputSelect'
import { SecondRoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import SimpleMenu from '../../../../../components/Navigation/SimpleMenu';
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import ContentText from '../../../../../components/Texts/ContentText';
import { red } from '@material-ui/core/colors';

const filter_form = [
  ['username','user_type_id','first_name','password','password_confirmation','last_name','gender_id','timezone'],
  ['employee_status_id','employee_type_id', 'position','office_id','area_id','supervisor_id','start_hour','end_hour'],
  ['work_email','work_phone'],
]

class ProfileForm extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    step:0,
  }

  render(){

    const {onCancel, language, isloading, error} = this.props
    const {formData, step} = this.state

    const content = contentData[language]

    let temp_form = {...formData}
    temp_form['password_confirmation'].value2 = temp_form['password'].value

    let formContent = null

    formContent = Object.keys(temp_form).map((item,key)=>{

      if(filter_form[step].find(el => el === item)){
        let inputContent = null
        switch (temp_form[item].config.type) {
          case 'select':
            inputContent = (
              <ShadowInputSelect 
                data={temp_form[item]}
                onChange={this.onInputChange}/>
            )
            break;
          
          default:
            inputContent = (
              <ShadowInputText data={temp_form[item]} onChange={this.onInputChange}/>
            )
            break;
        }
        return(
          <Grid item xs={12} md={6} key={key.toString()}>
            {inputContent}
          </Grid>
        )
      }else{
        return null
      }
      
    })

    let infoContent = null

    if(isloading){
      infoContent = <SimpleLoader />
    }else{
      if(error){
        infoContent = <ContentText text={error} medium color={red[700]}/>
      }
    }

    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <SimpleMenu 
                  selected={step}
                  options={content.menu_form}
                  onChange={this.onChangeTab}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={4}>
                  {formContent}
                </Grid>
              </Grid>
            </Grid>
            
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justify='flex-end' alignItems='center'>
              <Grid item xs>
                <SecondRoundedButton label='Cancelar' size='small' color='grey' onClick={onCancel}/>
              </Grid>
              <Grid item>
                {infoContent}
              </Grid>
              {step ? <Grid item>
                <SecondRoundedButton label='Regresar' size='small' color='grey' onClick={this.onBack}/>
              </Grid> : null}
              
              <Grid item>
                <SecondRoundedButton label={step <2 ? 'Siguiente' : 'Crear'} size='small' onClick={step < 2 ? this.onNext : this.onSubmit}/>
              </Grid>
            </Grid>
            
          </Grid>
        </Grid>
        
      </div>
    )
  }

  onNext = () => {
    const {step} = this.state

    let response = this.onValidateStep()

    if(response){
      let temp = step
      temp++;
      if(temp > 2){
        temp = 2
      }
      this.setState({step:temp})
    }
    
  }

  onValidateStep = () => {
    const {step, formData} = this.state
    const current_keys = filter_form[step]
    let temp = {...formData}
    let response = true
    current_keys.forEach(item => {
      if(temp[item].isRequired && !temp[item].isValid){
        response = false
        temp[item].isVisited = true
      }
    })

    if(!response){
      this.setState({formData:{...temp}})
    }

    return response

  }



  onBack = () => {
    const {step} = this.state
    let temp = step
    temp--;
    if(temp < 0){
      temp = 0
    }
    this.setState({step:temp})
  }

  componentDidMount(){
    const {language, catalogs} = this.props
    const {formData} = this.state
    const content = contentData[language]
    let temp = {...formData}
    //Setting catalogs
    temp['user_type_id'].options = catalogs['user_types'] ? [...catalogs['user_types']] : []
    temp['gender_id'].options = catalogs['genders'] ? [...catalogs['genders']] : []
    temp['employee_status_id'].options = catalogs['employee_statuses'] ? [...catalogs['employee_statuses']] : []
    temp['office_id'].options = catalogs['offices'] ? [...catalogs['offices']] : []
    temp['area_id'].options = catalogs['areas'] ? [...catalogs['areas']] : []
    temp['supervisor_id'].options = catalogs['supervisors'] ? [...catalogs['supervisors']] : []
    temp['timezone'].options = catalogs['timezones'] ? [...catalogs['timezones']] : []
    temp['employee_type_id'].options = catalogs['employee_types'] ? [...catalogs['employee_types']] : []

    Object.keys(formData).forEach((item) => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    })
    this.setState({formData:{...temp}})
  }

  onChangeTab = (id) => {
    this.setState({step:id})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {formData} = this.state;

    let data2Send = {};
    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid && item !== 'password_confirmation'){
        data2Send = {...data2Send, [item]:formData[item].value}
      }
    })
    onSubmit(data2Send)
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(ProfileForm)