import Dashboard from "../views/Dashboard/Dashboard/Dashboard";
import EditProfileCard from "../views/Dashboard/EditProfileCard/EditProfileCard";
import Users from "../views/Users/Users/Users";
import AddUser from "../views/Users/AddUser/AddUser";
import UserView from "../views/Users/UserView/UserView";
import EditUser from "../views/Users/EditUser/EditUser";
import Areas from "../views/Areas/Areas/Areas";


const dashboard = {
  sections:[
    {
      path: "/me",
      icon:'person',
      label:'Mis datos',
      component: Dashboard,
      sidebar:true,
      exact:true,
      level:2
    },
    {
      path: "/me/edit_profile",
      label:'Mis datos',
      component: EditProfileCard,
      exact:true,
      level:1
    },
    {
      path: "/users",
      icon:'group',
      label:'Usuarios',
      component: Users,
      sidebar:true,
      exact:true,
      level:1
    },
    {
      path: "/users/add",
      label:'Usuarios',
      component: AddUser,
      exact:true,
      level:1
    },
    {
      path: "/users/:id",
      label:'Usuarios',
      component: UserView,
      exact:true,
      level:1
    },
    {
      path: "/users/edit/:id",
      label:'Usuarios',
      component: EditUser,
      exact:true,
      level:1
    },
    {
      path: "/areas",
      icon:'business',
      label:'Areas',
      component: Areas,
      sidebar:true,
      exact:true,
      level:1
    },
    { redirect: true, path: "/", to: "/me", navbarName: "Redirect" }
  ]
}

export default dashboard