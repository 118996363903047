import React, {Component} from 'react';
import { grey, red} from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { InputBase, withStyles, Icon, InputAdornment, Typography, Grid } from '@material-ui/core';
import ContentText from '../../Texts/ContentText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {ruleValidation} from '../customFunctions.jsx';


class ShadowInputSelect extends Component {

  render(){

    const {classes, disabled} = this.props

    const {value, isVisited, isValid, isRequired, options} = this.props.data;
    const {label,placeholder, fullWidth, helper} = this.props.data.config;
    
    const error = isVisited && !isValid;

    let inputlabel = null;
    if(label){
      let signal = isRequired ? '*' : ''
      inputlabel = (
        <Grid item>
          <ContentText text={label+signal} variant='light' medium/>
        </Grid>
      )
    }

    let option_selected = null
    if(value){
      let aux = options.find(el => el.value === value)
      option_selected = {...aux}
    }

    return (
      <Grid container>
        {inputlabel}
        <Grid item xs={12}>
          <Autocomplete 
            classes={{
              paper:classes.paper
            }}
            disabled={disabled}
            options={options ? options : []}
            onChange={this.onInnerChange}
            value={option_selected}
            getOptionLabel={option => option.label}
            noOptionsText={<ContentText text='No options'/>}
            renderOption={(option) => (
              <div style={{padding:8, paddingLeft:0, paddingRight:0}}>
                <ContentText text={option.label}/>
              </div>
            )}
            renderInput={params => (
              <InputBase
                placeholder={placeholder}
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                fullWidth={fullWidth}
                className={classes.inputBase}
                endAdornment={
                  <InputAdornment position='end'>
                    <Icon className={classes.icon_arrow}>arrow_drop_down</Icon>
                  </InputAdornment>
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='caption' style={{color:red[700], fontWeight:500}}>
            {error ? helper : ''}
          </Typography>
        </Grid>
      </Grid>
      
    );
  }
  
  onInnerChange = (event, option) => {
    const {onChange, data} = this.props;
    const response = option ? option.value : null;
    let temp = {...data};
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

}

const styles = (theme) => ({
  root:{},
  paper:{
    maxHeight:250,
    overflowY:'auto'
  },
  inputBase: {
    background: fade(theme.palette.primary.light,0.2),
    borderRadius:8,
    marginTop:6,
    '& input': {
      color:grey[900],
      fontSize:14,
      fontWeight:'500',
      borderBottomWidth:0,
      borderRadius:8,
      padding:16,
      paddingRight:0,
      '&:focus': {
        //boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        //borderColor: theme.palette.primary.main,
      },
      
    },
  },
  icon_arrow:{
    color:grey[500],
    paddingRight:8
  }
})

export default withStyles(styles)(ShadowInputSelect)